import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
// import classnames from 'classnames';
import { graphql /*, navigate */ } from 'gatsby';

import storage, { STORAGE_KEY_MY_LOADOUT } from '~utils/storage';
import { loadList } from '~redux/ducks/loadout';
import Container from '~components/Container';
import NonHome from '~templates/NonHome';
import BackArrow from '~components/BackArrow';
import WeaponCategoryButton from '~components/WeaponCategoryButton';

export default function Page({ data, location }) {
    const dispatch = useDispatch();
    const [loadout] = useSelector((state) => {
        return [state.loadout];
    });

    useEffect(() => {
        const weapons = storage.get(STORAGE_KEY_MY_LOADOUT);

        if (weapons) {
            dispatch(loadList(weapons));
        }
    }, []);

    const siteTitle = get(data, 'site.siteMetadata.title');

    function renderLoadoutWeapons() {
        if (!loadout.list.length) {
            return <p>You have not added any weapons to your loadout yet.</p>;
        }

        return loadout.list.map((weapon, i) => {
            return <WeaponCategoryButton key={i} {...weapon} />;
        });
    }
    return (
        <NonHome location={location}>
            <Helmet title={`My Loadout | ${siteTitle}`} />
            <Container>
                <header>
                    <h1>
                        <BackArrow path="/" title="Back to all guns" /> My
                        Loadout
                    </h1>
                </header>
                <div>{renderLoadoutWeapons()}</div>
            </Container>
        </NonHome>
    );
}

Page.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export const pageQuery = graphql`
    query LoadoutQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
